import {
	SIGNATURE_UPDATE,
	LOAD_SIGN_TAB,
	PHOTO_UPDATE,
	GET_MY_WORK_DATA_LOAD,
	GET_MY_WORK_DATA_SUCCESS,
	SET_CONTRACT_TAB,
	SET_CONTRACT_TYPE_DATA,
} from '../actions/constants';

const initialState = {
	isLoading: false,
	myWorkData: {},
	contractId: '',
	sign: '',
	signTab: true,
	initialScreen: 'sign',
	photo: '',
	photoFlag: false,
	contractTab: 'rentalTab',
	contractTypes: []
};

function myWorkReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SIGNATURE_UPDATE:
			const { signData, flag } = payload;
			return {
				...state,
				sign: signData,
				signFlag: flag,
				initialScreen: 'photo'
			};
		case PHOTO_UPDATE:
			const { photoData, pFlag } = payload;
			return {
				...state,
				photo: photoData,
				photoFlag: pFlag,
				initialScreen: 'photo'
			};
		case LOAD_SIGN_TAB:
			return {
				...state,
				initialScreen: payload
			};
		case GET_MY_WORK_DATA_LOAD:
			return {
				...state,
				isLoading: true
			};
		case GET_MY_WORK_DATA_SUCCESS:
			return {
				...state,
				isLoading: false,
				myWorkData: payload
			};
		case SET_CONTRACT_TAB:
			return {
				...state,
				contractTab: payload,
			}
		case SET_CONTRACT_TYPE_DATA:
			return {
				...state,
				contractTypes: payload
			}
		default:
			return state;
	}
}

export default myWorkReducer;
