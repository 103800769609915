import React from "react";
import { Fragment } from "preact";
import { useState } from "preact/hooks";
import AskExpertIcon from "../../assets/expert.png";
import AskExpertForm from "./askExpertFields";
import messages from "../messages";
import "../../style/contract.css";
import "../../style/askExpert.css";

const {
  page: {
    askExperts: { askExpertTitle },
  },
} = messages;

const AskExpert = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const togglePop = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <Fragment>
      <div class="askExpert">
        <img src={AskExpertIcon} alt="askExpert" onClick={togglePop} />
        <h2>{askExpertTitle.expert}</h2>
      </div>
      <AskExpertForm togglePop={togglePop} modalOpen={modalOpen} />
    </Fragment>
  );
};

export default AskExpert;
