import React from "react";
import { useEffect, useState } from "preact/hooks";
import { route } from "preact-router";
import ModalPopup from "../../utils/modal";
import SingleFileUpload from "../fileUpload/singleFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, removeTextAreaNextLineTag } from "../../utils/helpers";
import {
  getRequest,
  postRequest,
  formDataRequest,
  deleteRequest,
} from "../../model";
import {
  ASK_EXPERT_GET_MAIN_CATEGORY,
  ASK_EXPERT_DOCUMENT_UPLOAD,
  ASK_EXPERT_API,
  FILE_DELETE_API,
} from "../../model/constant";
import {
  askExpertFileLoader,
  updateSource,
  updateCurrentTab,
  askExpertFormLoader,
} from "../../actions/askExpert";
import {
  getAskExpertFileLoader,
  getAskExpertFormLoader,
} from "../../selectors/askExpert";
import { toastMessage } from "../../actions/app";
import {
  askExpertInitialState,
  askExpertInitialStateError,
  fileUploadValidationMsgState,
} from "./_helper";
import { askExpertUploadValidation } from "../../utils/constant";
import Loader from "../loader";
import messages from "../messages";
import "../../style/contract.css";
import "../../style/askExpert.css";

const {
  page: {
    validationMessage: { required, fileRemoved, wentWrong, fileUpload },
    askExperts: { askExpertTitle, labelName, askExpertMsg },
  },
} = messages;

const AskExpertForm = ({ togglePop, modalOpen }) => {
  const dispatch = useDispatch();
  const [askExpertState, setAskExpertState] = useState(askExpertInitialState);
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState({});
  const [askExpertBtnDisabled, setAskExpertBtnDisabled] = useState(true);
  const [errState, setErrState] = useState(askExpertInitialStateError);
  const [document, setDocument] = useState([]);
  const loading = useSelector((state) => getAskExpertFormLoader(state));
  const fileLoading = useSelector((state) => getAskExpertFileLoader(state));
  const userDetail = getUserDetails();

  const onFileUploadCB = (file) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    setAskExpertBtnDisabled(true);
    dispatch(askExpertFileLoader(true));
    formDataRequest(ASK_EXPERT_DOCUMENT_UPLOAD, formData, "POST", "json").then(
      (data) => {
        dispatch(askExpertFileLoader(false));
        if (data.slug) {
          const documentArray = [];
          documentArray.push(data);
          setDocument((prev) => [...prev, ...documentArray]);
          dispatch(toastMessage(fileUpload, "success"));
          submitButtonEnableStatus();
        } else {
          dispatch(toastMessage(wentWrong, "danger"));
        }
      }
    );
  };

  useEffect(() => {
    if (modalOpen) {
      setAskExpertState(askExpertInitialState);
      setDocument([]);
      dispatch(askExpertFormLoader(true));
      getAskMainCategory();
    }
  }, [modalOpen]);

  useEffect(() => {
    submitButtonEnableStatus();
  }, [errState]);

  const getAskMainCategory = () => {
    getRequest(ASK_EXPERT_GET_MAIN_CATEGORY, "json").then((data) => {
      const { expertCategoryDTOs, languageDTOs } = data;
      dispatch(askExpertFormLoader(false));
      setMainCategoryData(expertCategoryDTOs);
      setLanguageData(languageDTOs);
    });
  };

  const onFormChange = (e, name) => {
    const { value } = e.target;
    setAskExpertState((prev) => ({ ...prev, [name]: value }));
    if (Object.keys(askExpertInitialStateError).includes(name)) {
      if (!value) {
        setErrState((prev) => ({ ...prev, [name]: required }));
        setAskExpertBtnDisabled(true);
      } else {
        setErrState((prev) => ({ ...prev, [name]: false }));
      }
    }
  };

  const submitButtonEnableStatus = () => {
    setAskExpertBtnDisabled(!isFormValid());
  };

  const isFormValid = () => {
    return Object.values(errState)?.every((val) => val === false);
  };

  const onChangeMainCategory = (e) => {
    setSubCategoryData({});
    const { value } = e.target;
    onFormChange(e, "mainCategory");
    const filterCategoryData = mainCategoryData.filter(
      (c) => `${c.id}` === value
    );
    setTimeout(() => {
      setSubCategoryData(filterCategoryData[0]);
    }, 100);
  };

  const modalClose = () => {
    setAskExpertState(askExpertInitialState);
    setAskExpertBtnDisabled(true);
    setErrState(askExpertInitialStateError);
    togglePop();
  };

  const renderRadioBtn = (id, value, labelName, className, name) => {
    return (
      <div class={`${className} col-xs-12 col-sm-4`}>
        <div
          class="form-group radioBtn"
          onChange={(e) => onFormChange(e, name)}
        >
          <input
            type="radio"
            checked={askExpertState[name] === value}
            value={value}
            name={name}
            id={id}
          />
          <label for={id}>{labelName}</label>
        </div>
      </div>
    );
  };

  const renderCheckBox = (id, labelName, className, name) => {
    return (
      <div class={`${className} col-sm-6`}>
        <div class="form-group checkbox">
          <input
            type="checkbox"
            name={name}
            id={id}
            checked={askExpertState[name]}
            onChange={(e) => onCheckBoxChange(e, name)}
          />
          <label for={id}>{labelName}</label>
        </div>
      </div>
    );
  };

  const onCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setAskExpertState((prev) => ({ ...prev, [name]: checked }));
  };

  const askExpertFormSubmit = (e) => {
    e.preventDefault();
    for (const key in askExpertInitialStateError) {
      if (!askExpertState[key]) {
        setErrState((prev) => ({ ...prev, [key]: required }));
      }
    }
    if (isFormValid()) {
      const documentId = document.map((item) => item.id);
      const formObj = {
        categoryId: askExpertState.mainCategory,
        subCategoryId: askExpertState.subCategory,
        documentId,
        query: removeTextAreaNextLineTag(askExpertState.query),
        contactMedium: askExpertState.contactMedium,
        languageId: askExpertState.language,
        documentWriterRequired: askExpertState.documentWriterRequired,
        legalRequired: askExpertState.legalRequired,
      };
      dispatch(askExpertFormLoader(true));
      postRequest(ASK_EXPERT_API, formObj, "POST").then((data) => {
        if (data === 201 || data === 202) {
          togglePop();
          dispatch(updateCurrentTab("openTab"));
          dispatch(updateSource());
          route("/askExpertAlert", true);
          dispatch(toastMessage(askExpertMsg.success, "success", ""));
        } else {
          dispatch(toastMessage(wentWrong, "danger"));
        }
      });
    }
  };

  const onFileRemove = (slug) => {
    dispatch(askExpertFileLoader(true));
    setAskExpertBtnDisabled(true);
    deleteRequest(`${FILE_DELETE_API}/${slug}`)
      .then((data) => {
        dispatch(askExpertFileLoader(false));
        if (data === 204) {
          const cloneUploadListState = JSON.parse(JSON.stringify(document));
          const filterAfterDelete = cloneUploadListState.filter(
            (doc) => doc.slug !== slug
          );
          setDocument(filterAfterDelete);
          dispatch(toastMessage(fileRemoved, "success"));
          submitButtonEnableStatus();
        } else {
          dispatch(toastMessage(wentWrong, "danger"));
        }
      })
      .catch((err) => {
        dispatch(toastMessage(wentWrong, "danger"));
      });
    const remainDoc = document.filter((doc) => doc.slug !== slug);
    setDocument(remainDoc);
  };

  const formInputs = () => {
    return loading ? (
      <Loader />
    ) : (
      <div class="dynamicForm documentView">
        <form onSubmit={askExpertFormSubmit}>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <label>{labelName.fname}</label>
              <span class="dName">{userDetail?.firstName}</span>
            </div>
            <div class="col-xs-12 col-sm-6">
              <label>{labelName.lname}</label>
              <span class="dName">{userDetail?.lastName}</span>
            </div>
            <div class="col-xs-12 col-sm-6">
              <label>{labelName.mobile}</label>
              <span class="dName">{`${userDetail?.phoneCode}-${userDetail?.phoneNumber}`}</span>
            </div>
            <div class="col-xs-12 col-sm-6">
              <label>{labelName.email}</label>
              <span class="dName">{userDetail?.email}</span>
            </div>
            <div class="form-group col-sm-12 mt-2">
              <label class="form-label" for="mainCategory">
                {labelName.mCategory}
                <span class="mandatory" title="Field Mandatory">
                  *
                </span>
              </label>
              <select
                onChange={(e) => onChangeMainCategory(e)}
                name="mainCategory"
                id="mainCategory"
                class="selectBox"
              >
                <option value="">{labelName.mCategoryOptions}</option>
                {mainCategoryData?.length &&
                  mainCategoryData.map((main) => {
                    const { label, id } = main;
                    return <option value={id}>{label}</option>;
                  })}
              </select>
              {errState?.mainCategory && (
                <div class="error">{errState.mainCategory}</div>
              )}
            </div>
            <div class="form-group col-sm-12">
              <label class="form-label" for="subCategory">
                {labelName.sCategory}
              </label>
              <select
                onChange={(e) => onFormChange(e, "subCategory")}
                name="subCategory"
                id="subCategory"
                class="selectBox"
                disabled={!subCategoryData?.subItem?.length}
              >
                <option value="">{labelName.sCategoryOptions}</option>
                {subCategoryData?.subItem?.length &&
                  subCategoryData?.subItem.map((main) => {
                    const { label, id } = main;
                    return <option value={id}>{label}</option>;
                  })}
              </select>
            </div>
            <div class="form-group col-sm-12">
              <label class="form-label" for="subCategory">
                {labelName.language}
              </label>
              <select
                onChange={(e) => onFormChange(e, "language")}
                name="language"
                id="language"
                class="selectBox"
              >
                <option value="">{labelName.languageOptions}</option>
                {languageData?.length &&
                  languageData.map((language) => {
                    const { label, id } = language;
                    return (
                      <option
                        selected={askExpertState.language === id}
                        value={id}
                      >
                        {label}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <div class="row">
                <label>{labelName.expertType}</label>
                {renderCheckBox(
                  "documentWriterRequired",
                  labelName.docWriter,
                  "pl0",
                  "documentWriterRequired"
                )}
                {renderCheckBox(
                  "legalRequired",
                  labelName.legal,
                  "pl0",
                  "legalRequired"
                )}
              </div>
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <div class="row">
                <label>
                  {labelName.medium}
                  <span class="mandatory" title="Field Mandatory">
                    *
                  </span>
                </label>
                {renderRadioBtn(
                  "Online",
                  "ONLINE",
                  labelName.online,
                  "pr0",
                  "contactMedium"
                )}
                {renderRadioBtn(
                  "Phone",
                  "PHONE",
                  labelName.phone,
                  "pl0",
                  "contactMedium"
                )}
                {renderRadioBtn(
                  "Video",
                  "VIDEO",
                  labelName.video,
                  "pr0",
                  "contactMedium"
                )}
                {errState?.contactMedium && (
                  <div class="error">{errState.contactMedium}</div>
                )}
              </div>
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-12 ask-doubts">
              <label class="form-label" for="doubt">
                {labelName.doubts}
                <span class="mandatory" title="Field Mandatory">
                  *
                </span>
              </label>
              <textarea
                id="doubt"
                name="doubt"
                placeholder={labelName.doubts}
                maxLength="1500"
                onChange={(e) => onFormChange(e, "query")}
              />
              {errState?.query && <div class="error">{errState.query}</div>}
              <div class="char-length">{askExpertState.query.length}/1500</div>
            </div>
            <div class="col-sm-12 mb-2">
              <SingleFileUpload
                validState={fileUploadValidationMsgState}
                validInputs={askExpertUploadValidation}
                onFileUploadCB={onFileUploadCB}
                document={document}
                handleRemoveFileCB={onFileRemove}
                isLoading={fileLoading}
              />
            </div>
            <div class="col-sm-12 text-end">
              <div>
                <button type="button" class="backButton" onClick={modalClose}>
                  {labelName.cancel}
                </button>
                <button type="submit" disabled={askExpertBtnDisabled}>
                  {labelName.submit}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div id="askExpert">
      <ModalPopup
        modalOpen={modalOpen}
        togglePop={togglePop}
        children={formInputs()}
        title={askExpertTitle.expert}
      />
    </div>
  );
};

export default AskExpertForm;
