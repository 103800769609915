import { Fragment } from "preact";
import { useEffect } from "preact/hooks";
import Cancel from "../../assets/icons/cancel.png";
import "./modal.css";

const ModalPopup = ({
  togglePop,
  children,
  title,
  closeBtn = true,
  modalOpen,
  modalWidthClass = '',
}) => {
	
  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modalOpen]);

  const handleClick = () => {
    togglePop();
  };

  return (
    modalOpen && (
      <Fragment>
        <div className="modalPopUp">
          <div className={`modal_content ${modalWidthClass}`}>
            <div className="modalHeader">
              <h5 class="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              {closeBtn && (
                <button
                  type="button"
                  class="btnClose"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClick}
                >
                  <img src={Cancel} alt="close" />
                </button>
              )}
            </div>
            <div className="modalBody">{children}</div>
            <div className="modalFooter"></div>
          </div>
        </div>
      </Fragment>
    )
  );
};

export default ModalPopup;
