import { Fragment } from "preact";
import { Link } from "preact-router/match";
import { useSelector, useDispatch } from "react-redux";
import { getAgreementActive } from "../../actions/sideMenu";
import { getStringWithThreeDot } from "../../utils/helpers";
import Logo from "../../assets/icons/weassent-logo.svg";
import Logout from "../../assets/icons/logout.svg";
import LogoutMobile from "../../assets/icons/logoutMobile.svg";

const Header = ({ handleLogout, menuDisable }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const onResMenuOpen = () => {
    document.body.classList.add("resMenuOpen");
    document.body.classList.remove("fade-out");
  };
  return (
    <div class="d-flex eusHeader">
      <div class="logo">
        <div class="weassentLogo">
          <Link
            activeClassName="active"
            href="/home"
            onClick={() => dispatch(getAgreementActive(false))}
          >
            <img src={Logo} alt="weAssent" />
          </Link>
        </div>
        <div class="headerResMenu">
          <HeaderMenu
            handleLogout={handleLogout}
            screen="mobile"
            menuDisable={menuDisable}
          />
          {!menuDisable && (
            <div class="hMenu">
              <button onClick={onResMenuOpen}>
                <i class="fa fa-bars" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div class="headerMenu">
        <HeaderMenu
          handleLogout={handleLogout}
          screen="desktop"
          menuDisable={menuDisable}
        />
      </div>
    </div>
  );
};

const HeaderMenu = ({ handleLogout, screen, menuDisable }) => {
  let displayName = "";
  const userDetailsSession = window.localStorage.getItem("userDetails");
  if(userDetailsSession){
    const userDetails = JSON.parse(userDetailsSession);
    const { firstName, lastName } = userDetails;
    displayName = firstName ? `${firstName} ${lastName}` : "";
  }

  return (
    !menuDisable && (
      <Fragment>
        {displayName && (
          <span class="welcomeName" title={displayName}>
            Hi, {getStringWithThreeDot(displayName, 15)}
          </span>
        )}
        <Link activeClassName="active" href="/askExpertAlert" title="notification">
          <i class="fa fa-bell" aria-hidden="true" />
          <span class="notificationAlert"></span>
        </Link>
        <Link activeClassName="active" href="/profile" title="Profile">
          <i class="fa fa-user" aria-hidden="true" />
        </Link>
        <Link
          class="logout"
          activeClassName="active"
          onClick={handleLogout}
          title="logout"
        >
          <img src={screen === "mobile" ? LogoutMobile : Logout} alt="logout" />
        </Link>
      </Fragment>
    )
  );
};

export default Header;
