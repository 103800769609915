import { openWithNewTab } from '../../utils/helpers';
export default function Footer() {
	return (
		<div class="col-sm-12 footer text-center">
			<div class="footerLinks">
				<ul>
					<li>
						<a href="#" onClick={() => openWithNewTab('/privacy-policy')}>
							Privacy Policy
						</a>
					</li>
					<li>
						<a href="#" onClick={() => openWithNewTab('/terms-and-conditions')}>
							Terms and Conditions
						</a>
					</li>
				</ul>
			</div>
			<div class="text-white mb-3 mb-md-0">
				Copyright &copy; 2021. All rights reserved.
			</div>
		</div>
	);
}
