import {
	GET_FORM_STATE_VALUE_GENERIC,
	SET_STEPPER_ACTIVE_GENERIC,
	IS_VIEW_ACTIVE_GENERIC,
	IS_EDIT_ACTIVE_GENERIC,
	DOCUMENT_UPLOAD_GENERIC,
	SET_INITIAL_STATE_GENERIC,
	VIEW_AGREEMENT_GENERIC,
	SET_CONTRACT_TYPE_ID_GENERIC,
} from '../actions/constants';

const initialState = {
	slug: '',
	stepperActive: 1,
	stepper: ['Generic', 'Lender', 'Borrower', 'Terms'],
	formNames: ['type', 'vendorDetails', 'vendeeDetails', 'agreement'],
	roleDisplayName: {'VENDOR': 'Lender','VENDEE': 'Borrower' ,'DOCUMENT_WRITER': 'Document Writer' ,'LEGAL_COUNSEL': 'Legal Counsel'},
	isView: false,
	isEdit: true,
	canEdit: false,
	isUpdate: false,
	role: 'VENDOR',
	contractTypeId: '',
	type: {
		name: 'free-form-contract',
		isDocumentWriterRequired: false,
		isCounselRequired: false,
	},
	vendorDetails: {
		country: '',
		zipCode: '',
		city: '',
		firstName: '',
		lastName: '',
		state: '',
		address: ''
	},
	vendeeDetails: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		address: '',
		city: '',
		state: '',
		country: '',
		zipCode: '',
		phoneCode: '',
		comments: ''
	},
  document: [],
	agreement: {},
};

function RentalAgreementReducer(state = initialState, action) {
	const { payload, type } = action;
	switch (type) {
		case SET_INITIAL_STATE_GENERIC:
			return {
				...initialState,
			};
		case GET_FORM_STATE_VALUE_GENERIC:
			const { localState, localStateName } = payload;
			if (localStateName) {
				return {
					...state,
					[localStateName]: localState,
				};
			} else {
				return {
					...state,
					...localState
				};
			}

		case SET_STEPPER_ACTIVE_GENERIC:
			return {
				...state,
				stepperActive: payload
			};

		case IS_VIEW_ACTIVE_GENERIC:
			return {
				...state,
				isView: payload
			};

		case IS_EDIT_ACTIVE_GENERIC:
			return {
				...state,
				canEdit: payload
			};
		case DOCUMENT_UPLOAD_GENERIC:
			return {
				...state,
				document: payload
			}
		case VIEW_AGREEMENT_GENERIC:
			return {
				...state,
				...payload
			}
		case SET_CONTRACT_TYPE_ID_GENERIC:
			return {
				...state,
				contractTypeId: payload,
			}
		default:
			return state;
	}
}

export default RentalAgreementReducer;
