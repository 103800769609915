import { NEW_AGREEMENT_ACTIVE } from '../actions/constants';

const initialState = {
	newAgreement: false
};

function SideMenuReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case NEW_AGREEMENT_ACTIVE:
			return {
				...state,
				newAgreement: payload
			};
		default:
			return state;
	}
}

export default SideMenuReducer;
