import { h, Fragment } from 'preact';
import '../../style/loader.css';

const Loader = () => (
	<Fragment>
		<div class="loader" />
		<div class="textLoad">Loading...</div>
	</Fragment>
);

export default Loader;
