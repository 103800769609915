import {
  DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  countryArray,
  MONTH_THREE_FORMAT,
  MONTH_NUMBER_FORMAT,
} from "./constant";

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? "" : results[1].replace(/\+/g, " ");
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const findEmptyObject = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const dateConversion = (str) => {
  const date = str.split(" ");
  return [date[3], MONTH_NUMBER_FORMAT[date[1]], date[2]].join("-");
};

export const convertBytesToKB = (bytes) =>
  `${(bytes / DEFAULT_MAX_FILE_SIZE_IN_BYTES).toFixed(2)} MB`;

export const getEmptyMark = (value) => value || "-";

export const getStringWithThreeDot = (str, len) => {
  return str.length < len ? str : `${str.substring(0, len) + "..."}`;
};

export const findStringInURL = (findUrl) => {
  return window.location.pathname.split("/")[2] === findUrl;
};

export const getDataToFile = (data) => {
  let fileData = [];
  if (data) {
    const blobBin = atob(data.split(",")[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    fileData = new Blob([new Uint8Array(array)], { type: "image/png" });
  }
  return fileData;
};

export const openWithNewTab = (url) => {
  window.open(`${window.location.origin}${url}`);
};

export const setLocalStorage = (name, data) => {
  window.localStorage.setItem(name, data);
};

export const getLocalStorageData = (name) => {
  window.localStorage.getItem(name);
};

export const removeLocalStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const legalCheckBoxRender = (isRequired, labelName, name) => {
  return (
    <div class="form-group checkbox">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={isRequired}
        disable={true}
      />
      <label>{labelName}</label>
    </div>
  );
};

export const labelWithInfo = (labelName, fieldName) => {
  return (
    <div class="col-sm-12 col-md-6 col-lg-3">
      <label>{labelName}</label>
      {labelName === "Phone" ? (
        <span class="dName">
          {fieldName.phoneCode
            ? `+${getEmptyMark(fieldName.phoneCode)}-${getEmptyMark(
                fieldName.phone
              )}`
            : "-"}
        </span>
      ) : (
        <span class="dName">{getEmptyMark(fieldName)}</span>
      )}
    </div>
  );
};

export const getCountryName = (code) => {
  const country = countryArray.filter((c) => c.code === code);
  return country[0].name || "";
};

export const getUserDetails = () => {
  let userDetails = window.localStorage.getItem("userDetails") || null;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  return userDetails;
};

export const isUserCheckFn = () => {
  let roles = [];
  const userDetailsSession = window.localStorage.getItem("userDetails");
  if (userDetailsSession) {
    const { roles: role } = JSON.parse(userDetailsSession);
    roles = role;
  }
  return !!roles.length && roles.includes("USER");
};

export const getDateFormat = (dateValue) => {
  const date = new Date(dateValue);
  return `${date.getDate()} ${MONTH_THREE_FORMAT[
    date.getMonth()
  ].toUpperCase()} ${date.getFullYear()}`;
};

export const removeTextAreaNextLineTag = (str) => {
  return str.replace(/\n/g, "<br />")
}