import {
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
  OTP_SENT_LOAD,
  OTP_SENT_SUCCESS,
  MOBILE_UPDATE,
  PAGE_INLINE_LOADER,
} from "../actions/constants";

const initialState = {
  isLoading: true,
  isUserLoggedIn: false,
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  phoneVerified: false,
  emailVerified: false,
  isFailed: false,
  // errTheme: "",
  optSent: "",
  phoneCode: null,
  basicInfoLoad: true,
  changePwdLoad: true,
  contactDetailsLoad: true,
};

function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROFILE_INFO_SUCCESS:
      const {
        active,
        firstName,
        lastName,
        phoneNumber,
        email,
        phoneVerified,
        emailVerified,
        phoneCode,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        userActive: active,
        firstName,
        lastName,
        mobile: phoneNumber,
        email,
        phoneCode,
        phoneVerified,
        emailVerified,
      };
    case GET_PROFILE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      };
    case OTP_SENT_LOAD:
      return {
        ...state,
        optSent: true,
      };
    case OTP_SENT_SUCCESS:
      return {
        ...state,
        optSent: false,
      };
    case MOBILE_UPDATE:
      return {
        ...state,
        mobile: action.payload,
        phoneVerified: false,
      };
    case PAGE_INLINE_LOADER:
      const { source, flag } = action.payload;
      return {
        ...state,
        [source]: flag,
      };
    default:
      return state;
  }
}

export default ProfileReducer;
