export const sellerBuyerInitialState = {
  age: "", 
  email: "",
  firstName: "",
  lastName: "",
  address: {
    addressLine: "",
    city: "",
    country: "",
    pinCode: "",
    state: "",
  },
  phone: "",
  phoneCode: "", 
  proofDetails: "",
  proofType: null,
  relationDetails: "",
  relationType: null,  
};

export const sellerInitialState = {
  addressLine: "",
  age: "",
  city: "",
  country: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  phoneCode: "",
  pinCode: "",
  proofDetails: "",
  proofType: "",
  relationDetails: "",
  relationType: "",
  state: "",
};

export const buyerErrorObj = {
  addressLine: "",
  age: "",
  city: "",
  country: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  phoneCode: "",
  pinCode: "",
  proofDetails: "",
  proofType: "",
  relationDetails: "",
  relationType: "",
  state: "",
};

export const natureOfDocOptions = [
  { name: "Aadhar", value: "AADHAR" },
  { name: "Driving License", value: "DRIVING_LIC" },
  { name: "Voter Id", value: "VOTER_ID" },
  { name: "Passport", value: "PASSPORT" },
];
export const documentTypeOptions = [
  { name: "Original", value: "ORIGINAL" },
  { name: "Photo Copy", value: "PHOTOCOPY" },
];
export const userTypeOptions = [
  { name: "Seller", value: "VENDOR" },
  { name: "Buyer", value: "VENDEE" },
];
export const legalTypeOptions = [
  { name: "Seller", value: "VENDOR" },
  { name: "Buyer", value: "VENDEE" },
  { name: "Document Writter", value: "DOCUMENT_WRITER" },
  { name: "Legal", value: "COUNSELLOR" },
];

export const propertyInitialObj = {
  yardSize: "",
  surveyNo: "",
  plotNo: "",
  propertyAddress: {
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
  },
  sro: "",
  saleDeed: "",
  documentNo: "",
  purchasedFrom: "",
  propertyType: null,
  boundariesSouth: "",
  boundariesNorth: "",
  boundariesEast: "",
  boundariesWest: "",
  extent: "",
  saleDate: "",
  saleAmount: "",
};

export const propertyInitialErrObj = {
  yardSize: "",
  surveyNo: "",
  plotNo: "",
  addressLine: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
  sro: "",
  saleDeed: "",
  documentNo: "",
  purchasedFrom: "",
  propertyType: null,
  boundariesSouth: "",
  boundariesNorth: "",
  boundariesEast: "",
  boundariesWest: "",
  extent: "",
  saleDate: "",
  saleAmount: "",
};

export const addressArray = ["addressLine", "city", "state", "country", "pinCode"];

export const paymentInitialObj = {
  srNo: "",
  amountReceived: "",
  paymentDate: "",
  paymentMode: "",
};

export const DocumentInitialObj = {
  contract_id: "",
  userType: "",
  documentType: "",
  documentNature: "",
  documentFor: "",
};

export const statementInitialObj = {
  village: "",
  surveyNo: "",
  propertyDescription: "",
  extent: "",
  price: "",
  consideration: "",
  doorNo: "",
  deficitStampDuty: "",
  registrationFee: "",
  userCharge: "",
  treasuryDuty: "",
};

export const witnessInitialObj = {
  firstName: "",
  lastName: "",
};

export const setReadOnly = ["email", "phoneCode", "phone", "country"];