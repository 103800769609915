export const getAskExpertCurrentTab = (state) => {
  return state.ask.currentTab;
}

export const getAskExpertLoader = (state) => {
  return state.ask.askLoad;
}

export const getAskRequestList = (state) => {
  return state.ask.requestList;
}

export const getAskExpertFileLoader = (state) => {
  return state.ask.fileLoad;
}

export const getAskExpertSummaryLoader = (state) => {
  return state.ask.summaryLoad;
}

export const getAskExpertSummaryData = (state) => {
  return state.ask.summary;
}

export const getClickFrom = (state) => {
  return state.ask.source;
}

export const getAskExpertFormLoader = (state) => {
  return state.ask.formLoader;
}
