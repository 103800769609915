import { useState, useRef, useEffect } from "preact/hooks";
import { convertBytesToKB, getStringWithThreeDot } from "../../utils/helpers";
import messages from "../messages";
import Loader from "../../components/loader";
import UploadError from "./uploadError";
import "../../style/fileUpload.css";

const {
  page: {
    validationMessage: { emptyMsg },
  },
} = messages;

const SingleFileUpload = ({
  validState,
  validInputs,
  document,
  onFileUploadCB,
  handleRemoveFileCB,
  isLoading,
}) => {
  const [fileValidationError, setFileValidationError] = useState(validState);
  const [isFileUpload, setIsFileUpload] = useState(true);

  const fileInputFieldRef = useRef(null);

  const handleUploadBtnClick = () => {
    fileInputFieldRef.current.click();
  };

  const onHandleFileUploadVisible = () => {
    setIsFileUpload(!isFileUpload);
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (fileValidation(file)) {
      onFileUploadCB(file);
    } else {
      fileInputFieldRef.current.value = null;
    }
  };

  const fileValidation = (file) => {
    const [fileType, fileFormat] = file.type.split("/");
    const { maxSize, maxCount, type, format } = validInputs;
    const sizeValidator = parseFloat(convertBytesToKB(file.size)) <= maxSize;
    const typeValidator = type.includes(fileType);
    const formatValidator = format.includes(fileFormat);
    const countValidator = maxCount ? (document.length < maxCount ? 1 : 2) : 4;
    fileInputFieldRef.current.value = null;
    setFileValidationError((prev) => ({
      ...prev,
      size: sizeValidator ? 1 : 2,
      type: typeValidator && formatValidator ? 1 : 2,
      count: countValidator,
    }));
    return (
      sizeValidator &&
      typeValidator &&
      formatValidator &&
      [1, 4].includes(countValidator)
    );
  };

  const handleRemove = (slug) => {
    setFileValidationError(validState);
    handleRemoveFileCB(slug);
  };

  return (
    <div class="form-group col-sm-12 px0">
      <button
        type="button"
        class="fileVisible"
        onClick={onHandleFileUploadVisible}
      >
        <span>{isFileUpload ? `-` : `+`}</span> Add Supporting Document
        (Optional)
      </button>
      {isFileUpload && (
        <div class="row m-0">
          <div class="col-sm-12 upload-error">
            <UploadError
              fileValidationError={fileValidationError}
              emptyMsg={emptyMsg}
              validInputs={validInputs}
            />
          </div>
          <div class="col-xl-6 fileUploadContainer">
            <div class="head">Upload Files</div>
            <div class="innerBox">
              <div class="uploadIcon">
                <i class="fa fa-file-image" aria-hidden="true"></i>
              </div>
              <div class="options">
                <span>
                  Drag & Drop your files
                  <br />
                  or
                </span>
                <button type="button" onClick={handleUploadBtnClick}>
                  Browse file
                </button>
              </div>
            </div>
            <input
              ref={fileInputFieldRef}
              type="file"
              id="file"
              name="file"
              single
              onChange={handleFileChange}
            />
          </div>
          <div class="col-xl-6 fileLoader">
            {document && !!document.length && (
              <div class="fileShow">Selected Files</div>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              document &&
              !!document.length &&
              document.map((file) => {
                return (
                  <div>
                    <div class="filePreview">
                      <div class="name">
                        <span>
                          {getStringWithThreeDot(file.documentFileName, 40)}
                        </span>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => handleRemove(file.slug)}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleFileUpload;
