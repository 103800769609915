
export const getMessageStatus = (state) => {
  return state.app.msgStatus;
}
export const getMessage = (state) => {
  return state.app.message;
}

export const getToastInfo = (state) => {
  return state.app.toast;
}

export const isLoggedIn = (state) => {
  return state.app.isUserLoggedIn;
}

export const getToasterMessageRedirect = (state) => {
  return state.app.toast.redirect;
}