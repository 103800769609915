import {
	GET_FORM_STATE_VALUE,
	SET_STEPPER_ACTIVE,
	IS_VIEW_ACTIVE,
	IS_EDIT_ACTIVE,
	DOCUMENT_UPLOAD,
	SET_INITIAL_STATE,
	VIEW_AGREEMENT,
	SET_CONTRACT_TYPE_ID_RENTAL,
} from '../actions/constants';

const initialState = {
	slug: '',
	stepperActive: 1,
	stepper: ['Rental', 'Owner', 'Property / Rent', 'Tenant'],
	formNames: ['type', 'vendorDetails', 'leaseTerm', 'vendeeDetails'],
	roleDisplayName: {'VENDOR': 'Owner','VENDEE': 'Tenant' ,'DOCUMENT_WRITER': 'Document Writer' ,'LEGAL_COUNSEL': 'Legal Counsel'},
	isView: false,
	isEdit: true,
	canEdit: false,
	isUpdate: false,
	role: 'VENDOR',
	contractTypeId: '',
	type: {
		name: 'rental-contract',
		isDocumentWriterRequired: false,
		isCounselRequired: false,
	},
	vendorDetails: {
		country: '',
		zipCode: '',
		city: '',
		firstName: '',
		lastName: '',
		state: '',
		address: ''
	},
	leaseTerm: {
		country: '',
		zipCode: '',
		city: '',
		state: '',
		address: '',
		term: '',
		startDate: '',
		endDate: '',
		rent: '',
		advance: ''
	},
	vendeeDetails: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		address: '',
		city: '',
		state: '',
		country: '',
		zipCode: '',
		phoneCode: '',
		comments: ''
	},
	document: [],
};

function RentalAgreementReducer(state = initialState, action) {
	const { payload, type } = action;
	switch (type) {
		case SET_INITIAL_STATE:
			return {
				...initialState,
			};
		case GET_FORM_STATE_VALUE:
			const { localState, localStateName } = payload;
			if (localStateName) {
				return {
					...state,
					[localStateName]: localState,
				};
			} else {
				return {
					...state,
					...localState
				};
			}

		case SET_STEPPER_ACTIVE:
			return {
				...state,
				stepperActive: payload
			};

		case IS_VIEW_ACTIVE:
			return {
				...state,
				isView: payload
			};

		case IS_EDIT_ACTIVE:
			return {
				...state,
				canEdit: payload
			};
		case DOCUMENT_UPLOAD:
			return {
				...state,
				document: payload
			}
		case VIEW_AGREEMENT:
			return {
				...state,
				...payload
			}
		case SET_CONTRACT_TYPE_ID_RENTAL:
			return {
				...state,
				contractTypeId: payload,
			}
		default:
			return state;
	}
}

export default RentalAgreementReducer;
