import { Link } from "preact-router/match";
import { getAgreementActive } from "../../actions/sideMenu";
import { getInitialState } from "../../actions/rentalAgreement";
import { getInitialStateGeneric } from "../../actions/genericAgreement";
import { getInitialStateSaleAgreement } from "../../actions/salesAgreement";
import { findStringInURL, getStringWithThreeDot } from "../../utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import ListIcon from "../../assets/icons/list.svg";
import ListActiveIcon from "../../assets/icons/listActive.svg";

const SideMenu = ({ menuDisable }) => {
  const state = useSelector((state) => state);
  let displayName = "";
  const userDetailsSession = window.localStorage.getItem("userDetails");
  if (userDetailsSession) {
    const userDetails = JSON.parse(userDetailsSession);
    const { firstName, lastName } = userDetails;
    displayName = firstName ? `${firstName} ${lastName}` : "";
  }
  const dispatch = useDispatch();
  const {
    sideMenu: { newAgreement },
  } = state;

  const onHandleMenuDropDown = () => {
    dispatch(getAgreementActive(!newAgreement));
  };

  const onNewAgreementInActive = () => {
    dispatch(getAgreementActive(false));
    document.body.classList.remove("resMenuOpen", "fade-out");
  };

  const onResMenuRemoveClass = () => {
    document.body.classList.remove("resMenuOpen");
    document.body.classList.add("fade-out");
  };

  const removeBodyClass = () => {
    document.body.classList.remove("resMenuOpen", "fade-out");
  };

  const createNewWizard = () => {
    removeBodyClass();
    dispatch(getInitialState());
    dispatch(getInitialStateGeneric());
    dispatch(getInitialStateSaleAgreement());
  };

  return (
    !menuDisable && (
      <div class="menuSec">
        <div class="d-block d-sm-block d-md-block d-lg-none close">
          <button onClick={onResMenuRemoveClass}>
            <i class="fa fa-times" aria-hidden="true" />
          </button>
        </div>
        <div class="menu-with-name">
          <h4>
            we assent{" "}
            {displayName && (
              <span class="welcomeName" title={displayName}>
                Hi, {getStringWithThreeDot(displayName, 15)}
              </span>
            )}
          </h4>
        </div>
        <ul>
          <li>
            <Link
              activeClassName="active"
              href="/home"
              onClick={onNewAgreementInActive}
            >
              <img class="beforeActive" src={ListIcon} />
              <img class="afterActive" src={ListActiveIcon} />
              My Work
            </Link>
          </li>
          <li>
            <button
              class={`dropdown-btn ${newAgreement ? "clsActive" : ""}`}
              onClick={onHandleMenuDropDown}
            >
              <img class="beforeActive" src={ListIcon} />
              <img class="afterActive" src={ListActiveIcon} />
              New Agreement
              <i class={`fa fa-caret-${newAgreement ? "up" : "down"}`}></i>
            </button>
            <ul class={`${newAgreement ? "showDown" : "showUp"}`}>
              <li>
                <Link
                  activeClassName="active"
                  class={`${findStringInURL("rental") ? "active" : ""}`}
                  href="/contract/rental"
                  onClick={createNewWizard}
                >
                  <span class="circle"></span>
                  Rental
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active"
                  class={`${findStringInURL("generic") ? "active" : ""}`}
                  href="/contract/generic"
                  onClick={createNewWizard}
                >
                  <span class="circle"></span>
                  Generic
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active"
                  class={`${findStringInURL("real-estate") ? "active" : ""}`}
                  href="/contract/real-estate"
                  onClick={createNewWizard}
                >
                  <span class="circle"></span>
                  Real Estate
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              activeClassName="active"
              href="/profile"
              onClick={onNewAgreementInActive}
            >
              <img class="beforeActive" src={ListIcon} />
              <img class="afterActive" src={ListActiveIcon} />
              Profile
            </Link>
          </li>
          <li>
            <Link
              activeClassName="active"
              href="/askExpertAlert"
              onClick={onNewAgreementInActive}
            >
              <img class="beforeActive" src={ListIcon} />
              <img class="afterActive" src={ListActiveIcon} />
              Ask an Expert
            </Link>
          </li>
        </ul>
      </div>
    )
  );
};

export default SideMenu;
