import {
	SET_INITIAL_STATE_SALE_AGREEMENT,
	SET_STEPPER_ACTIVE_SALE_AGREEMENT,
	IS_VIEW_ACTIVE_SALE_AGREEMENT,
	GET_FORM_STATE_VALUE_SALE_AGREEMENT,
	SET_CONTRACT_TYPE_ID_SALE_AGREEMENT,
	UPDATE_ROLE_DOC_LEGAL_STATUS,
	SAVE_CONTRACT_ID_SALE_AGREEMENT,
	INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT,
	VIEW_SALES_AGREEMENT,
	IS_EDIT_ACTIVE_SALES_AGREEMENT,
	REAL_ESTATE_CONTRACT_TYPE_UPDATE,
	SET_CONTRACT_TYPE_ID_SALE_DEED,
	DOCUMENT_UPDATE_SALE_AGREEMENT,
} from './constants';

export const getInitialStateSaleAgreement = () => {
	return {
		type: SET_INITIAL_STATE_SALE_AGREEMENT,
	}
}

export const setStepperActiveSalesAgreement = (step) => {
	return {
		type: SET_STEPPER_ACTIVE_SALE_AGREEMENT,
		payload: step
	};
};

export const isViewActiveStepper = (isView) => {
	return {
		type: IS_VIEW_ACTIVE_SALE_AGREEMENT,
		payload: isView
	};
};

export const getFormStateValueSaleAgreement = (localState, localStateName) => {
	return {
		type: GET_FORM_STATE_VALUE_SALE_AGREEMENT,
		payload: {
			localState,
			localStateName
		}
	};
};

export const documentUpdate = (document) => {
	return {
		type: DOCUMENT_UPDATE_SALE_AGREEMENT,
		payload: document,
	};
};

export const setSaleAgreementTypeId = (typeId) => {
	return {
		type: SET_CONTRACT_TYPE_ID_SALE_AGREEMENT,
		payload: typeId
	}
}

export const setDeedTypeId = (typeId) => {
	return {
		type: SET_CONTRACT_TYPE_ID_SALE_DEED,
		payload: typeId
	}
}

export const updateHelpPage = (data) => {
	return {
		type: UPDATE_ROLE_DOC_LEGAL_STATUS,
		payload: data,
	}
}

export const saveContractId = (contractId) => {
	return {
		type: SAVE_CONTRACT_ID_SALE_AGREEMENT,
		payload: contractId,
	}
}
export const loaderIsEnable = (flag) => {
	return {
		type: INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT,
		payload: flag,
	}
}

export const viewSalesAgreement = (data) => {
	return {
		type: VIEW_SALES_AGREEMENT,
		payload: data
	}
}

export const isEditActiveSalesAgreement = (canEdit) => {
	return {
		type: IS_EDIT_ACTIVE_SALES_AGREEMENT,
		payload: canEdit
	};
};

export const contractTypeUpdate = (type) => {
	return {
		type: REAL_ESTATE_CONTRACT_TYPE_UPDATE,
		payload: type
	};
}