import {
  SET_INITIAL_STATE_SALE_AGREEMENT,
  SET_STEPPER_ACTIVE_SALE_AGREEMENT,
  IS_VIEW_ACTIVE_SALE_AGREEMENT,
  GET_FORM_STATE_VALUE_SALE_AGREEMENT,
  SET_CONTRACT_TYPE_ID_SALE_AGREEMENT,
  UPDATE_ROLE_DOC_LEGAL_STATUS,
  SAVE_CONTRACT_ID_SALE_AGREEMENT,
  INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT,
  VIEW_SALES_AGREEMENT,
  IS_EDIT_ACTIVE_SALES_AGREEMENT,
  REAL_ESTATE_CONTRACT_TYPE_UPDATE,
  SET_CONTRACT_TYPE_ID_SALE_DEED,
  DOCUMENT_UPDATE_SALE_AGREEMENT,
} from "../actions/constants";
import { realEstateType } from "../utils/constant";
import {
  propertyInitialObj,
  statementInitialObj,
} from "../components/realEstate/initialObject";

const initialState = {
  contractTypeId: "",
  subContractId: "",
  isDocumentWriterRequired: true,
  isCounselRequired: false,
  role: "",
  slug: "",
  stepperActive: 1,
  salesAgreementSelection: null,
  stepperUser: ["Help", "Seller", "Buyer", "Documents"],
  stepper: [
    "Help",
    "Seller",
    "Buyer",
    "Property",
    "Payments",
    "Documents",
    "Rule3",
    "Witness",
  ],
  formNames: [
    "type",
    "vendorDetailsList",
    "vendeeDetailsList",
    "propertyDetails",
    "paymentParticulars",
    "statement",
    "witness",
  ],
  roleDisplayName: {
    VENDOR: "Seller",
    VENDEE: "Buyer",
    DOCUMENT_WRITER: "Document Writer",
    LEGAL_COUNSEL: "Legal Counsel",
  },
  isView: false,
  isEdit: true,
  canEdit: false,
  isUpdate: false,
  tab: realEstateType,
  tabId: "" ,
  contract: {
    type: "",
    contractRole: "",
    vendorDetailsList: [],
    vendeeDetailsList: [],
    propertyDetails: { ...propertyInitialObj },
    paymentParticulars: [],
    statement: { ...statementInitialObj },
    witness: [],
  },
  contractId: null,
  loader: false,
  parentContractName: '',
  contractDocument: [],
};

function RentalAgreementReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_INITIAL_STATE_SALE_AGREEMENT:
      return {
        ...initialState,
      };
    case INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT:
      return {
        ...state,
        loader: payload,
      };
    case SET_STEPPER_ACTIVE_SALE_AGREEMENT:
      return {
        ...state,
        stepperActive: payload,
      };
    case IS_VIEW_ACTIVE_SALE_AGREEMENT:
      return {
        ...state,
        isView: payload,
      };

    case GET_FORM_STATE_VALUE_SALE_AGREEMENT:
      const { localState, localStateName } = payload;
      if (localStateName) {
        return {
          ...state,
          contract: {
            ...state.contract,
            [localStateName]: localState,
          },
        };
      } else {
        return {
          ...state,
          ...localState,
        };
      }
    case SET_CONTRACT_TYPE_ID_SALE_AGREEMENT:
      return {
        ...state,
        contractTypeId: payload,
        tabId: payload ,
      };
    case UPDATE_ROLE_DOC_LEGAL_STATUS:
      const {
        role,
        isDocumentWriterRequired,
        isCounselRequired,
        salesAgreementSelection,
      } = payload;
      return {
        ...state,
        isDocumentWriterRequired,
        isCounselRequired,
        salesAgreementSelection,
        role,
        contract: {
          ...state.contract,
          contractRole: role,
        },
      };
    case SAVE_CONTRACT_ID_SALE_AGREEMENT:
      return {
        ...state,
        contractId: payload,
      };
    case VIEW_SALES_AGREEMENT:
      return {
        ...state,
        ...payload,
      };
    case IS_EDIT_ACTIVE_SALES_AGREEMENT:
      return {
        ...state,
        canEdit: payload,
      };
    case REAL_ESTATE_CONTRACT_TYPE_UPDATE: {
      return {
        ...state,
        contract: {
          ...state.contract,
          type: payload,
        },
      };
    }
    case SET_CONTRACT_TYPE_ID_SALE_DEED:
      return {
        ...state,
        contractTypeId: payload,
      };

    case DOCUMENT_UPDATE_SALE_AGREEMENT:
      return {
        ...state,
        contractDocument: payload,
      }
    default:
      return state;
  }
}

export default RentalAgreementReducer;
