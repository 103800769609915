import { route } from "preact-router";

const window = window || global.window || {};
const baseUrl = window["API_HOST_NAME"] || "http://localhost:8081";
const headers = {
  "Content-Type": "application/json",
};

const baseConfig = {
  headers,
  mode: "cors",
  cache: "default",
  credentials: "include",
  "Access-Control-Allow-Origin": "*",
};

const unAuthorized = (response, req, page = null) => {
  const { ok, status } = response;
  if (status >= 200 && status < 300 && ok) {
    if (req === "json") {
      return response.json();
    }
    return status;
  } else if (status >= 400 && status < 500) {
    if (status === 401 && req === "json" && page === 'login') {
      return response.json();
    } else {
      route("/logout", true);
    }
  } else {
    return status;
  }
};

const logout = () => {
  const req = new Request(`${baseUrl}/logout`, {
    ...baseConfig,
    method: "POST",
  });
  return fetch(req).then((response) => {
    const { ok, status } = response;
    if (ok && status === 200) {
      return response.ok;
    }
  });
};

const isAuthenticate = () => {
  const req = new Request(`${baseUrl}/api/is-authenticated`, {
    ...baseConfig,
    method: "GET",
  });
  return fetch(req).then((response) => {
    return response;
  });
};

const getRequest = (url, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    ...baseConfig,
    method: "GET",
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes);
  });
};

const postRequest = (url, payLoad, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    ...baseConfig,
    method: "POST",
    body: JSON.stringify(payLoad),
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes);
  });
};

const loginSignupPostRequest = (url, payLoad, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    ...baseConfig,
    method: "POST",
    body: JSON.stringify(payLoad),
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes, 'login');
  });
};

const putRequest = (url, payLoad, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    ...baseConfig,
    method: "PUT",
    body: JSON.stringify(payLoad),
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes);
  });
};

const deleteRequest = (url, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    ...baseConfig,
    method: "DELETE",
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes);
  });
};

const formDataRequest = (url, formData, method, reqRes = "") => {
  const req = new Request(`${baseUrl}${url}`, {
    mode: "cors",
    cache: "default",
    credentials: "include",
    "Access-Control-Allow-Origin": "*",
    header: { "Content-Type": "multipart/form-data" },
    method,
    body: formData,
  });
  return fetch(req).then((response) => {
    return unAuthorized(response, reqRes);
  });
};

export {
  baseUrl,
  logout,
  isAuthenticate,
  getRequest,
  postRequest,
  putRequest,
  formDataRequest,
  deleteRequest,
  loginSignupPostRequest,
};
