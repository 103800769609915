import {
	PAGE_REDIRECT,
	USER_LOGGED_IN_SUCCESS,
	INITIAL_LOAD,
	LOGIN_SUCCESS,
	INITIAL_LOAD_DONE,
	SIGNUP_SUCCESS_MSG,	
	TOAST_MSG,
} from '../actions/constants';

const initialState = {
	redirectCode: '',
	isLoading: false,
	isUserLoggedIn: false,
	loginErrorMsg: '',
	loginTxtColor: '',
	signUpMsgCode: '',
	toast: {toastMsg: '', bgColor: '', redirect: ''},
};

function AppReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case INITIAL_LOAD:
			return {
				...state,
				isLoading: true
			};
		case INITIAL_LOAD_DONE: {
			return {
				...state,
				isLoading: false
			};
		}
		case PAGE_REDIRECT:
			return {
				...state,
				redirectCode: payload
			};
		case USER_LOGGED_IN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isUserLoggedIn: payload
			};
		case LOGIN_SUCCESS:
			const { msg, color } = payload;
			return {
				...state,
				isLoading: false,
				loginErrorMsg: msg,
				loginTxtColor: color
			};
		case SIGNUP_SUCCESS_MSG:
			return {
				...state,
				isLoading: false,
				signUpMsgCode: payload
			};
		case TOAST_MSG:
			const { toastMsg, bgColor, redirect } = payload
			return {
				...state,
				toast: { toastMsg, bgColor, redirect }
			}
		default:
			return state;
	}
}

export default AppReducer;
