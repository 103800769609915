export const askExpertInitialState = {
  mainCategory: "",
  subCategory: "",
  query: "",
  contactMedium: "",
  documentWriterRequired: false,
  legalRequired: false,
  language: 2
};

export const askExpertInitialStateError = {
  mainCategory: "",
  query: "",
  contactMedium: ""
};

export const fileUploadValidationMsgState = {
  size: 3, // display
  type: 3, // display
  count: 3, // display
  duplicate: 4, // not display
  empty: 4, // not display
};