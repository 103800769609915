const messages = {
  page: {
    welcomeMsg: {
      weassent: "Welcome to Weassent",
      login:
        "To Keep connected with us please login with personal information by Mobile Number and password",
      singUp:
        "To Keep connected with us please Register with personal information by Mobile Number and password",
    },
    profile: {
      "basic.info": {
        header: "Basic Info",
        title: "Edit Information",
        validation: "Numbers, symbols are not Allowed",
        requiredfield: "Required Field",
        label: {
          firstname: "First Name",
          lastname: "Last Name",
        },
        button: {
          update: "Update",
          cancel: "Cancel",
        },
        placeholder: {
          firstname: "Enter your first name",
          lastname: "Enter your last name",
        },
        error: {
          success: "Basic Info Updated Successfully.",
          failure: "Something Went Wrong, please try again.",
        },
      },
      changepassword: {
        heading: "Change Password",
        info: "Reset your password here",
        notempty: "Field should not be empty.",
        requiredfield: "Required Field.",
        notmatch: "Current and New Password should not be same.",
        current: "Current Password",
        newer: "New Password",
        error: {
          success:
            "Password Changed Successfully. Kindly login with your new password.",
          failure: "Something Went Wrong, please try again.",
        },
        placeholder: {
          current: "Enter Current Password",
          newer: "Enter New Password",
        },
        button: {
          reset: "Reset",
          cancel: "Cancel",
        },
      },
      contactdetails: {
        header: "Contact Details",
        otphead: "OTP Verification",
        otpinfo: "Enter the OTP sent to",
        otpresend: "Do not receive the OTP?",
        mobileinfo: "Update Mobile Information",
        displayname: {
          email: "Email",
          mobile: "Mobile",
          verified: "Verified",
          notverified: "Not Verified",
        },
        label: {
          otp: "Enter OTP",
          mobile: "Mobile Number with country code",
          email: "Enter valid Email Address",
        },
        placeholder: {
          otp: "Enter 6 digit OTP",
          mobile: "Enter Mobile Number",
          email: "Enter valid Email Address",
        },
        button: {
          verify: "Verify",
          resend: "Resend OTP",
          getotp: "Get OTP",
          cancel: "Cancel",
        },
        error: {
          validemobile: "Should be enter valid phone number only (+123...).",
          requiredmobile:
            "Required Field. Mobile number start with country code +.",
          otpSentmsg: "OTP has been sent to your Registered Mobile Number",
          required: "Required Field.",
          successmsg: "Mobile Number verified successfully.",
          validotp: "Enter valid OTP",
          invalidotp: "You Entered Invalid OTP",
          failure: "Something went wrong!",
        },
      },
    },
    contract: {
      head: "Rental",
      help: "Need help with",
      created: "Your Rental Agreement Created Successfully",
      updated: "Your Rental Agreement Updated Successfully",
      wentWrong: "Something Went Wrong!.",
      required: "Required Field.",
      docWriter: "Document Writer?",
      legal: "Legal Counsel?",
      owner: "Owner Details",
      property: "Property Address",
      rent: "Rent & Term",
      tenant: "Tenant Details",
    },
    generic: {
      head: "Rental",
      help: "Need help with",
      created: "Your Generic Agreement created Successfully.",
      updated: "Your Rental Agreement update Successfully",
      wentWrong: "Something Went Wrong!.",
    },
    salesAgreement: {
      created: "Your's Sales Agreement update Successfully",
      wentWrong: "Something Went Wrong!.",
      fileRemoved: "File has been removed successfully.",
      fileUploaded: "File has been Uploaded successfully.",
      salesType: "I am",
      helpTxt: "Need help with",
      heading: "Sales Agreement",
      optionTxt: "Select Sales Agreement",
      newSaleDeed: "I am going to Create New",
    },
    otpdetails: {
      success: "OTP has been sent successfully",
      expired: "Entered OTP expired. Kindly try resend for getting new OTP",
      invalid: "Entered OTP is Invalid/Expired",
      resend: "OTP has been sent successfully",
      notreceived: "Not received your code?",
      resendtext: "Resend OTP",
      verifytext: "Verify OTP",
      verifiedMobile: "Mobile Number verified successfully.",
      verifiedEmail: "Email Address verified successfully.",
      successEmail: "OTP has been sent your registered email id",
      successMobile: "OTP has been sent your registered Mobile Number",
      alreadyRegister: "Already Registered",
      wentWrong: "Something Went Wrong",
      otpSent: "We are sending otp to your registered Mobile",
      enterOpt: "kindly enter the otp for your Mobile verification",
      otpLabel: "Enter Mobile OTP",
      title: "OTP Verification",
      forMobile: "for Mobile",
    },
    userAgree: {
      singUp: "By signing up, you agree to our",
      use: "Terms of Use",
      policy: "Privacy Policy",
      and: "and",
    },
    errorscreen: {
      logintext: "Go To Login",
      failure: "Something Went Wrong, please try again.",
      mobilesuccess: "Mobile Number must be 10 digits",
      shouldnumber: "Should Enter only number",
      mobileValid: "Enter valid Mobile Number",
      norecords: "No Records Found",
      allMandatory: "All Fields are Mandatory",
      pagenotfound: {
        code: "404",
        title: "Page Not Found.",
        content: "This page is missing or you assembled the link incorrectly.",
      },
      unauthorized: {
        code: "401",
        title: "No authorization found.",
        content:
          "This page is not publicly available. To access it please login first.",
      },
      accessdined: {
        code: "403",
        title: "Access Denied/Forbidden",
        content:
          "The page you were trying to reach is absolutely Forbidden for some reason.",
      },
    },
    signin: {
      header: "Login",
      info: "To begin this journey",
      buttontxt: "Login",
      ortxt: "or",
      loginotp: "Login via OTP",
      loginpassword: "Login via Password",
      haveaccount: `Don't have an account?`,
      anchorsignuptxt: "Sign Up",
      label: {
        username: "Mobile Number",
        google: "Login with Google",
        facebook: "Login with Facebook",
      },
      placeholder: {
        username: "Enter Registered Mobile Number",
      },
      error: {
        required: "Require Field.",
        invaliduser: "Invalid User Credentials",
        wentwrong: "something went wrong",
        usernameErr: "Enter Valid Mobile Number",
        newaccount:
          "There is a problem logging in. Please check your mobile and password or create an account.",
        invalidemail: "Enter Valid Email Id",
        invalidmobile: "Mobile Number should be number",
        otpmessage: "invalid OTP. must be 6 digits.",
        otpsuccessmsg: "OTP has been sent your Registered Email/Mobile Number.",
      },
    },
    signup: {
      header: "Create an Account",
      info: "Give us some of your information to get free access to weassent",
      alreadyaccountMsg: "You are already Registered with us. Please try Login",
      registered: "Successfully Registered. Kindly Login",
      buttontxt: "Get Started",
      alreadyaccountinfo: "Already have an account?",
      loginbtntxt: "Log In",
      label: {
        email: "Email",
        mobile: "Mobile",
        google: "Singup with Google",
        facebook: "Singup with Facebook",
      },
      placeholder: {
        email: "Enter a valid Email address",
        mobile: "Enter your Mobile number",
      },
    },
    validationMessage: {
      strOnly: "String Only",
      numOnly: "Number Only",
      ageRange: "Age must be between 1 - 100",
      notSameMobile: "Should not enter registered mobile Number",
      notSameEmail: "Should not enter registered Email Id",
      emailFormat: "email should be valid format",
      phoneFormat: "Accept only numbers. 10 digits. Should not start with 0.",
      alphaNumericFormat: "Symbols not Allowed",
      required: "Required Field",
      imageFormat: "Should alow jpg/png image files only",
      imageFormatWithPdf: "Should alow jpg/png/pdf files only",
      fileMb5: "File size should not exceed 5 MB",
      maxFile3: "Maximum 3 files to upload.",
      maxFile5: "Maximum 5 files to upload.",
      fileDuplicate: "Should not allow duplicate File.",
      emptyMsg: "Fields Should not be empty",
      amtExceedMsg: "Total Amount received value cannot exceed sales amount.",
      emailDuplicate: "Email ID already exists. Try another email id",
      mobileDuplicate:
        "Mobile Number already exists. Try another Mobile Number",
      wentWrong: "Something Went Wrong!.",
      fileRemoved: "File has been removed successfully.",
      fileUpload: "File has been uploaded successfully.",
      statusUpdate: "Status updated Successfully",
      chatClosed: "Chat Successfully Closed",
      noRecords: "No Recent Records Found.",
    },
    askExperts: {
      labelName: {
        fname: "First Name",
        lname: "Last Name",
        mobile: "Mobile Number",
        email: "Email Id",
        mCategory: "Main Category",
        sCategory: "Sub Category",
        language: "Language Options",
        expertType: "Expert Type",
        medium: "Contact Medium",
        doubts: "Ask Doubt/Question",
        mCategoryOptions: "Select Main Category",
        sCategoryOptions: "Select Sub Category",
        languageOptions: "Select Language Option",
        docWriter: "Document Writer",
        legal: "Legal",
        online: "Online",
        phone: "Phone",
        video: "Video",
        cancel: "Cancel",
        submit: "Submit",
        rating: "Rating",
        feedback: "Feedback (if any)",
        yes: "Yes",
        no: "No",
        done: "Done",
        feedback: "Feedback",
        ask: "Ask Again",
        reply: "Reply",
        notAssigned: "Not Assigned",
        assigned: "Assigned",
        waiting: "Waiting for Reply",
        open: "Open",
        closed: "Closed",
        askExpert: "Ask an Expert",
        documents: 'Documents:-',
        question: 'Question/Ask',
        answer: 'Answer',
      },
      askExpertMsg: {
        success: "Query Submitted Successfully",
        feedback: "Thank you for you valuable Feedback.",
      },
      askExpertTitle: {
        subHeading: "You can ask your doubts here.",
        expert: "Ask an Expert",
        feedback: "Feedback",
        status: "Status Change",
      },
      askExpertPlaceholder: {
        feedback: "Feedback/suggestion (if any)",
      },
      content: {
        statusConfirm: "Are you sure want to close the chat?",
      },
      summaryList: {
        tableLabel: {
          name: "Name",
          assignee: "Assignee",
          cDate: "Created Date",
          status: "Status",
          category: "Category",
          medium: "Medium",
        },
      },
    },
    copyright: {
      text: "Copyright 2021. All rights reserved",
    },
  },
  "login.error.message":
    "There is a problem logging in. Please check your mobile and password or create an account.",
  "password.reset.success":
    "Password Changed Successfully. Kindly login with your new password",
  "field.required": "Required Field",
  "current.new.password.match": "Current and New Password should not be same",
  "enter.new.password": "Enter New Password",
  "enter.current.password": "Enter Current Password",
  "signup.heading": "Create an Account",
  "signup.heading.info":
    "Give us some of your information to get free access to weassent",
  "signup.text": "Get Started",
  "login.message.account.exist": "Already have an account?",
  "login.btn.text": "Log In",
  "login.error.message":
    "There was a problem logging in. Check your email and password or create an account",
};

export default messages;
