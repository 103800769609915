import {
	SIGNATURE_UPDATE,
	LOAD_SIGN_TAB,
	PHOTO_UPDATE,
	GET_MY_WORK_DATA_LOAD,
	GET_MY_WORK_DATA_SUCCESS,
	SET_CONTRACT_TAB,
	SET_CONTRACT_TYPE_DATA
} from './constants';

export const signatureUpdate = (signData, flag) => {
	return {
		type: SIGNATURE_UPDATE,
		payload: {
			signData,
			flag
		}
	};
};
export const photoUpdate = (photoData, pFlag) => {
	return {
		type: PHOTO_UPDATE,
		payload: {
			photoData,
			pFlag
		}
	};
};
export const loadSignTab = (tabName) => {
	return {
		type: LOAD_SIGN_TAB,
		payload: tabName
	};
};

export const getMyWorkDataLoad = () => {
	return {
		type: GET_MY_WORK_DATA_LOAD
	};
};

export const getMyWorkDataSuccess = (data) => {
	return {
		type: GET_MY_WORK_DATA_SUCCESS,
    payload: data
	};
};

export const setContractTab = (tabName) => {
	return {
		type: SET_CONTRACT_TAB,
    payload: tabName
	};
}

export const setMasterContractTypeId = (contractsData) => {
	return {
		type: SET_CONTRACT_TYPE_DATA,
    payload: contractsData
	};
}
