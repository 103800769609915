import {
  ASK_EXPERT_CURRENT_TAB,
  ASK_EXPERT_LOADER,
  ASK_EXPERT_REQUEST_SUCCESS,
  ASK_EXPERT_FILE_LOADER,
  ASK_EXPERT_SUMMARY_ON_LOAD,
  ASK_EXPERT_SUMMARY_SUCCESS,
  ASK_EXPERT_UPDATE_SOURCE,
  ASK_EXPERT_FORM_LOADER,
} from "../actions/constants";

const initialState = {
  askLoad: false,
  fileLoad: false,
  formLoader: false,
  currentTab: "openTab",
  source: "",
  requestList: {},
  error: "",
  summaryLoad: false,
  summary: {},
};

const AskExpertReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ASK_EXPERT_LOADER:
      return {
        ...state,
        askLoad: payload,
      };
    case ASK_EXPERT_REQUEST_SUCCESS:
      return {
        ...state,
        requestList: payload,
        source: "",
        askLoad: false,
        error: "",
      };
    case ASK_EXPERT_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      };
    case ASK_EXPERT_FILE_LOADER:
      return {
        ...state,
        fileLoad: payload,
      }
    case ASK_EXPERT_SUMMARY_ON_LOAD:
      return {
        ...state,
        summaryLoad: true,
      }
    case ASK_EXPERT_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryLoad: false,
        summary: payload
      }
    case ASK_EXPERT_UPDATE_SOURCE:
      return {
        ...state,
        source: 'expertForm'
      }
    case ASK_EXPERT_FORM_LOADER:
      return {
        ...state,
        formLoader: payload,
      }
    default:
      return state;
  }
};

export default AskExpertReducer;
