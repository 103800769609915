import { useEffect, useState } from "preact/hooks";
import { useSelector, useDispatch } from "react-redux";
import { getToastInfo } from "../../selectors/app";
import { toastMessage } from "../../actions/app";
import "./toast.css";

export const Toast = () => {
  const dispatch = useDispatch();
  const toastData = useSelector((state) => getToastInfo(state));
  const { toastMsg, bgColor } = toastData;
  const [msg, SetMsg] = useState(false);

  useEffect(() => {
    toastMsg && SetMsg(true);
    setTimeout(() => {
      SetMsg(false);
      dispatch(toastMessage());
    }, 5000);
  }, [toastMsg, bgColor]);

  return (
    msg && (
      <div
        class={`toaster ${bgColor} align-items-center text-white bg-primary border-0`}
      >
        <div class="d-flex">
          <div class="toast-body">
            <div class="toastIcon">
              <i class="fa fa-bell" aria-hidden="true"></i>
            </div> 
            <div class="toastMsg">{toastMsg}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default Toast;
