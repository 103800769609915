import {
	NEW_AGREEMENT_ACTIVE
} from './constants';

export const getAgreementActive = (flag) => {
	return {
		type: NEW_AGREEMENT_ACTIVE,
		payload: flag
	};
};
