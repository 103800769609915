import {
  PAGE_REDIRECT,
  USER_LOGGED_IN_SUCCESS,
  INITIAL_LOAD,
  INITIAL_LOAD_DONE,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS_MSG,
  TOAST_MSG,
} from "./constants";

export const initialLoad = () => {
  return {
    type: INITIAL_LOAD,
  };
};

export const initialLoadDone = () => {
  return {
    type: INITIAL_LOAD_DONE,
  };
};

export const pageRedirect = (code) => {
  return {
    type: PAGE_REDIRECT,
    payload: code,
  };
};

export const isUserLoggedInSuccess = (flag) => {
  return {
    type: USER_LOGGED_IN_SUCCESS,
    payload: flag,
  };
};

export const loginSuccess = (color, msg) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { color, msg },
  };
};

export const signUpSuccessMsg = (msgCode) => {
  return {
    type: SIGNUP_SUCCESS_MSG,
    payload: msgCode,
  };
};

export const toastMessage = (toastMsg = "", bgColor = "", redirect = "") => {
  return {
    type: TOAST_MSG,
    payload: {
      toastMsg,
      bgColor,
      redirect,
    },
  };
};
