import { combineReducers } from 'redux';
import ProfileReducer from './reducers/profile';
import AppReducer from './reducers/app';
import RentalAgreementReducer from './reducers/rentalAgreement';
import GenericAgreementReducer from './reducers/genericAgreement';
import SideMenuReducer from './reducers/sideMenu';
import MyWorkReducer from './reducers/myWork';
import RealEstateSalesAgreementReducer from './reducers/realEstateSalesAgreement';
import AskExpertReducer from './reducers/askExpert'; 

export const rootReducer = combineReducers({
	profile: ProfileReducer,
	app: AppReducer,
	contract: RentalAgreementReducer,
	generic: GenericAgreementReducer,
	sideMenu: SideMenuReducer,
	myWork: MyWorkReducer,
	salesAgreement: RealEstateSalesAgreementReducer,
	ask: AskExpertReducer,
});

export default rootReducer;
