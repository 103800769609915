import {
  ASK_EXPERT_CURRENT_TAB,
  ASK_EXPERT_LOADER,
  ASK_EXPERT_REQUEST_SUCCESS,
  ASK_EXPERT_FILE_LOADER,
  ASK_EXPERT_SUMMARY_ON_LOAD,
  ASK_EXPERT_SUMMARY_SUCCESS,
  ASK_EXPERT_UPDATE_SOURCE,
  ASK_EXPERT_FORM_LOADER,
} from "./constants";

export const updateCurrentTab = (tabName) => ({
  type: ASK_EXPERT_CURRENT_TAB,
  payload: tabName,
});

export const askExpertLoader = (flag) => ({
  type: ASK_EXPERT_LOADER,
  payload: flag,
});

export const askExpertFileLoader = (flag) => ({
  type: ASK_EXPERT_FILE_LOADER,
  payload: flag,
});

export const askExpertRequestList = (category) => ({
  type: ASK_EXPERT_REQUEST_SUCCESS,
  payload: category,
});

export const askExpertSummaryLoader = () => ({
  type: ASK_EXPERT_SUMMARY_ON_LOAD,
});

export const askExpertSummary = (summary) => ({
  type: ASK_EXPERT_SUMMARY_SUCCESS,
  payload: summary,
});

export const updateSource = () => ({
  type: ASK_EXPERT_UPDATE_SOURCE,
});

export const askExpertFormLoader = (flag) => {
  return {
    type: ASK_EXPERT_FORM_LOADER,
    payload: flag,
  };
};
