import {
	GET_FORM_STATE_VALUE_GENERIC,
	SET_STEPPER_ACTIVE_GENERIC,
	IS_VIEW_ACTIVE_GENERIC,
	IS_EDIT_ACTIVE_GENERIC,
	DOCUMENT_UPLOAD_GENERIC,
	SET_INITIAL_STATE_GENERIC,
	VIEW_AGREEMENT_GENERIC,
	SET_CONTRACT_TYPE_ID_GENERIC,
} from './constants';

export const getFormStateValueGeneric = (localState, localStateName) => {
	return {
		type: GET_FORM_STATE_VALUE_GENERIC,
		payload: {
			localState,
			localStateName
		}
	};
};

export const setStepperActiveGeneric = (step) => {
	return {
		type: SET_STEPPER_ACTIVE_GENERIC,
		payload: step
	};
};

export const isViewActiveGeneric = (isView) => {
	return {
		type: IS_VIEW_ACTIVE_GENERIC,
		payload: isView
	};
};

export const isEditActiveGeneric = (canEdit) => {
	return {
		type: IS_EDIT_ACTIVE_GENERIC,
		payload: canEdit
	};
};

export const documentUploadGeneric = (files) => {
	return {
		type: DOCUMENT_UPLOAD_GENERIC,
		payload: files
	};
};

export const getInitialStateGeneric = () => {
	return {
		type: SET_INITIAL_STATE_GENERIC,
	}
}

export const viewAgreementGeneric = (data) => {
	return {
		type: VIEW_AGREEMENT_GENERIC,
		payload: data
	}
}

export const setGenericTypeId = (typeId) => {
	return {
		type: SET_CONTRACT_TYPE_ID_GENERIC,
		payload: typeId
	}
}