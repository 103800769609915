export const GET_PROFILE_INFO = "GET_PROFILE_INFO";
export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";
export const GET_PROFILE_INFO_FAILURE = "GET_PROFILE_INFO_FAILURE";
export const PAGE_REDIRECT = "PAGE_REDIRECT";
export const INITIAL_LOAD = "INITIAL_LOAD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_LOGGED_IN_SUCCESS = "USER_LOGGED_IN_SUCCESS";
export const INITIAL_LOAD_DONE = "INITIAL_LOAD_DONE";
export const SIGNUP_SUCCESS_MSG = "SIGNUP_SUCCESS_MSG";
export const IS_LOADING = "IS_LOADING";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const OTP_SENT_LOAD = "OTP_SENT_LOAD";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const MOBILE_UPDATE = "MOBILE_UPDATE";
export const GET_FORM_STATE_VALUE = "GET_FORM_STATE_VALUE";
export const SET_STEPPER_ACTIVE = "SET_STEPPER_ACTIVE";
export const IS_VIEW_ACTIVE = "IS_VIEW_ACTIVE";
export const IS_EDIT_ACTIVE = "IS_EDIT_ACTIVE";
export const DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD";
export const NEW_AGREEMENT_ACTIVE = "NEW_AGREEMENT_ACTIVE";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const VIEW_AGREEMENT = "VIEW_AGREEMENT";
export const SIGNATURE_UPDATE = "SIGNATURE_UPDATE";
export const LOAD_SIGN_TAB = "LOAD_SIGN_TAB";
export const PHOTO_UPDATE = "PHOTO_UPDATE";
export const GET_MY_WORK_DATA_LOAD = "GET_MY_WORK_DATA_LOAD";
export const GET_MY_WORK_DATA_SUCCESS = "GET_MY_WORK_DATA_SUCCESS";
export const PAGE_INLINE_LOADER = "PAGE_INLINE_LOADER";
export const SET_CONTRACT_TAB = "SET_CONTRACT_TAB";
export const SET_CONTRACT_TYPE_DATA = "SET_CONTRACT_TYPE_DATA";
export const SET_CONTRACT_TYPE_ID_RENTAL = "SET_CONTRACT_TYPE_ID_RENTAL";
export const GET_FORM_STATE_VALUE_GENERIC = "GET_FORM_STATE_VALUE_GENERIC";
export const SET_STEPPER_ACTIVE_GENERIC = "SET_STEPPER_ACTIVE_GENERIC";
export const IS_VIEW_ACTIVE_GENERIC = "IS_VIEW_ACTIVE_GENERIC";
export const IS_EDIT_ACTIVE_GENERIC = "IS_EDIT_ACTIVE_GENERIC";
export const DOCUMENT_UPLOAD_GENERIC = "DOCUMENT_UPLOAD_GENERIC";
export const SET_INITIAL_STATE_GENERIC = "SET_INITIAL_STATE_GENERIC";
export const VIEW_AGREEMENT_GENERIC = "VIEW_AGREEMENT_GENERIC";
export const SET_CONTRACT_TYPE_ID_GENERIC = "SET_CONTRACT_TYPE_ID_GENERIC";
export const TOAST_MSG = "TOAST_MSG";
export const SET_INITIAL_STATE_SALE_AGREEMENT = "SET_INITIAL_STATE_SALE_AGREEMENT";
export const SET_STEPPER_ACTIVE_SALE_AGREEMENT = "SET_STEPPER_ACTIVE_SALE_AGREEMENT";
export const IS_VIEW_ACTIVE_SALE_AGREEMENT = "IS_VIEW_ACTIVE_SALE_AGREEMENT";
export const GET_FORM_STATE_VALUE_SALE_AGREEMENT = "GET_FORM_STATE_VALUE_SALE_AGREEMENT";
export const SET_CONTRACT_TYPE_ID_SALE_AGREEMENT = "SET_CONTRACT_TYPE_ID_SALE_AGREEMENT";
export const UPDATE_ROLE_DOC_LEGAL_STATUS = "UPDATE_ROLE_DOC_LEGAL_STATUS";
export const SAVE_CONTRACT_ID_SALE_AGREEMENT="SAVE_CONTRACT_ID_SALE_AGREEMENT";
export const INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT = "INTERNAL_LOADER_IS_ENABLE_SALE_AGREEMENT";
export const VIEW_SALES_AGREEMENT = "VIEW_SALES_AGREEMENT";
export const IS_EDIT_ACTIVE_SALES_AGREEMENT = "IS_EDIT_ACTIVE_SALES_AGREEMENT";
export const REAL_ESTATE_CONTRACT_TYPE_UPDATE = "REAL_ESTATE_CONTRACT_TYPE_UPDATE";
export const SET_CONTRACT_TYPE_ID_SALE_DEED = "SET_CONTRACT_TYPE_ID_SALE_DEED";
export const DOCUMENT_UPDATE_SALE_AGREEMENT = "DOCUMENT_UPDATE_SALE_AGREEMENT";
export const ASK_EXPERT_CURRENT_TAB = "ASK_EXPERT_CURRENT_TAB";
export const ASK_EXPERT_LOADER = "ASK_EXPERT_LOADER";
export const ASK_EXPERT_REQUEST_SUCCESS = "ASK_EXPERT_REQUEST_SUCCESS";
export const ASK_EXPERT_REQUEST_ERROR = "ASK_EXPERT_REQUEST_ERROR";
export const ASK_EXPERT_FILE_LOADER = "ASK_EXPERT_FILE_LOADER";
export const ASK_EXPERT_SUMMARY_ON_LOAD = "ASK_EXPERT_SUMMARY_ON_LOAD";
export const ASK_EXPERT_SUMMARY_SUCCESS = "ASK_EXPERT_SUMMARY_SUCCESS";
export const ASK_EXPERT_UPDATE_SOURCE = "ASK_EXPERT_UPDATE_SOURCE";
export const ASK_EXPERT_FORM_LOADER = "ASK_EXPERT_FORM_LOADER";
