export const BASE_URL = window["API_HOST_NAME"] || "http://localhost:8081";
export const COOKIE_DOMAIN = window["COOKIE_DOMAIN"] || "localhost";
export const LOGIN_OTP = "/loginOtp";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const SIGNUP_OTP_CONFIRM = "/signup/confirm";
export const SIGNUP_OTP_RESEND = "/signup/resend";
export const GET_USER_DETAILS = "/api/user";
export const CHANGE_PASSWORD_API = "/api/user/change-password";
export const GENERATE_OTP_API = "/api/user/generate-otp";
export const OTP_VALIDATE_API = "/api/user/validate-otp";
export const IS_AUTHENTICATE_API = "/api/is-authenticated";
export const RENTAL_AGREEMENT_API = "/api/user-contract";
export const RENTAL_AGREEMENT_FETCH_API = "/api/dash-board";
export const DOWNLOAD_RENTAL_AGREEMENT = "/api/generate-contract";
export const FILE_DELETE_API = "/api/document";
export const DOCUMENT_DOWNLOAD_API = "/api/document/download";
export const RENTAL_SIGN_API = "/api/sign";
export const FETCH_ALL_CONTRACT_ID = "/api/master-contract";
export const CREATE_REAL_ESTATE_API = "/api/real-estate";
export const REAL_ESTATE_DOCUMENT_UPLOAD_API = "/api/document/real-estate";
export const REAL_ESTATE_SUBMIT_API = "/api/real-estate/submit";
export const REAL_ESTATE_FETCH_API = "/api/real-estate";
export const GET_SALES_AGREEMENT_ID = "/api/real-estate/saleAgreement";
export const ASK_EXPERT_GET_MAIN_CATEGORY = "/api/ask-expert/formOptions";
export const ASK_EXPERT_DOCUMENT_UPLOAD = "/api/document/ask-expert";
export const ASK_EXPERT_API = "/api/ask-expert";
export const ASK_EXPERT_REPLY_API = "/api/ask-expert/postMessage"
export const ASK_EXPERT_STATUS_UPDATE = "/api/ask-expert/status"
export const ASK_EXPERT_FEEDBACK_UPDATE = "/api/ask-expert/reviews"
export const ASK_EXPERT_DOCUMENT_DOWNLOAD = "/api/document/download/ask-expert";
