import { route } from "preact-router";
import { useEffect } from "preact/hooks";
import { useDispatch } from "react-redux";
import { isUserLoggedInSuccess } from "../../actions/app";
import { logout } from "../../model";
import { removeLocalStorage } from "../../utils/helpers";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = () => {
    logout().then(() => {
      dispatch(isUserLoggedInSuccess(false));
      removeLocalStorage("userDetails");
      removeLocalStorage("masterContract");
      route('/login', true);
    });
  };
  return null;
}
export default Logout;