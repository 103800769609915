import { Fragment } from "preact";
import { Router, route } from "preact-router";
import { useState } from "preact/hooks";
import { useSelector, useDispatch } from "react-redux";
import { logout, isAuthenticate, getRequest } from "../model/index";
import {
  initialLoad,
  isUserLoggedInSuccess,
  loginSuccess,
} from "../actions/app";
import { pageRedirect } from "../actions/app";
import { GET_USER_DETAILS } from "../model/constant";
import { HIDE_PAGE } from "../utils/constant";
import { setContractTab } from "../actions/myWork";
import {
  isUserCheckFn,
  removeLocalStorage,
  setLocalStorage,
} from "../utils/helpers";
import Header from "./header";
import Home from "../routes/home";
import Privacy from "../routes/privacy";
import Terms from "../routes/terms";
import Profile from "../routes/profile";
import Contract from "../routes/contract";
import Login from "../routes/login";
import Signup from "../routes/signup";
import NotFound from "../routes/404";
import Loader from "../components/loader";
import Footer from "./footer";
import SideMenu from "../components/sideMenu";
import messages from "./messages";
import Toast from "../utils/toast";
import AskExpertForm from "./askExpertForm";
import AskExpertAlert from "../routes/askExpertAlert";
import Logout from "./logout";
import "../style/font.css";
import "../style/header.css";
import "../style/home.css";
import "../style/footer.css";
import "../style/rte.css";

const App = () => {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const isActUser = isUserCheckFn();
  const [currentUrl, setCurrentUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [menuDisable, SetMenuDisable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const userDetailsSession = window.localStorage.getItem("userDetails");
  if (app) {
    const { isLoading, isUserLoggedIn } = app;
    setIsLoading(isLoading);
    setIsUserLoggedIn(isUserLoggedIn);
  } else {
    setIsLoading(true);
    setIsUserLoggedIn(false);
  }

  const handleRoute = (e) => {
    isMobile
      ? SetMenuDisable(false)
      : SetMenuDisable(HIDE_PAGE.includes(e.url));
    setCurrentUrl(e.url);
    userAccessCheck();
  };

  const handleLogout = (from = null) => {
    logout().then(() => {
      dispatch(isUserLoggedInSuccess(false));
      dispatch(setContractTab("rentalTab"));
      if (from === "pwd") {
        const { error } = messages.page.profile.changepassword;
        dispatch(loginSuccess("green", error.success));
      } else {
        dispatch(loginSuccess("", ""));
      }
      removeLocalStorage("userDetails");
      removeLocalStorage("masterContract");
      route("/login", true);
    });
  };

  const handlePageRefresh = () => {
    dispatch(initialLoad());
    userAccessCheck();
  };

  const userAccessCheck = () => {
    isAuthenticate().then((response) => {
      const { ok, status } = response;
      if (ok && status === 200) {
        dispatch(isUserLoggedInSuccess(true));
        let res = response.json();
        res.then((data) => {
          if (!data.profileCompleted) {
            route("/profile", true);
          } else {
            !userDetailsSession && getUserDetails();
          }
        });
      } else {
        dispatch(isUserLoggedInSuccess(false));
        dispatch(pageRedirect(status));
      }
    });
  };

  const getUserDetails = () => {
    getRequest(GET_USER_DETAILS, "json")
      .then((data) => {
        if (typeof data === "object" || data === 200) {
          setLocalStorage("userDetails", JSON.stringify(data));
        }
      })
      .catch((err) => {
        dispatch(
          toastMessage(messages.page.validationMessage.wentWrong, "danger")
        );
      });
  };

  return (
    <div id="app" class={isLoading ? "load" : ""}>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          {isUserLoggedIn ? (
            <Fragment>
              <div class="hamburgerMenu">
                {currentUrl && <SideMenu menuDisable={menuDisable} />}
              </div>
              <div class="overlay" />
              <div class="container">
                {currentUrl && (
                  <Header
                    selectedRoute={currentUrl}
                    menuDisable={menuDisable}
                    handleLogout={handleLogout}
                  />
                )}
                <div class="d-flex mainContent">
                  {currentUrl && <SideMenu menuDisable={menuDisable} />}
                  <div class={`contentSec ${menuDisable && "termsBox"}`}>
                    <Router onChange={handleRoute} onLoad={handleRoute}>
                      <Contract path="/contract/:page/:slug?" />
                      <Profile path="/profile" handleLogout={handleLogout} />
                      <AskExpertAlert
                        path="/askExpertAlert"
                        isActUser={isActUser}
                      />
                      <Privacy path="/privacy-policy" />
                      <Terms path="/terms-and-conditions" />
                      <Logout path="/logout" />
                      <Home path="/" onLoad={handlePageRefresh} />
                      <Home default onLoad={handlePageRefresh} />
                    </Router>
                    {isActUser && <AskExpertForm />}
                  </div>
                </div>
                <Footer />
              </div>
            </Fragment>
          ) : (
            <Router onChange={handleRoute}>
              <Login path="/" />
              <Login path="/login" />
              <Signup path="/signup" />
              <NotFound path="/notFound" />
              <Privacy path="/privacy-policy" />
              <Terms path="/terms-and-conditions" />
              <Logout path="/logout" />
              <Login default />
            </Router>
          )}
          <Toast />
        </Fragment>
      )}
    </div>
  );
};

export default App;
