import {
	GET_FORM_STATE_VALUE,
	SET_STEPPER_ACTIVE,
	IS_VIEW_ACTIVE,
	IS_EDIT_ACTIVE,
	DOCUMENT_UPLOAD,
	SET_INITIAL_STATE,
	VIEW_AGREEMENT,
	SET_CONTRACT_TYPE_ID_RENTAL,
} from './constants';

export const getFormStateValue = (localState, localStateName) => {
	return {
		type: GET_FORM_STATE_VALUE,
		payload: {
			localState,
			localStateName
		}
	};
};

export const setStepperActive = (step) => {
	return {
		type: SET_STEPPER_ACTIVE,
		payload: step
	};
};

export const isViewActive = (isView) => {
	return {
		type: IS_VIEW_ACTIVE,
		payload: isView
	};
};

export const isEditActive = (canEdit) => {
	return {
		type: IS_EDIT_ACTIVE,
		payload: canEdit
	};
};

export const documentUpload = (files) => {
	return {
		type: DOCUMENT_UPLOAD,
		payload: files
	};
};

export const getInitialState = () => {
	return {
		type: SET_INITIAL_STATE,
	}
}

export const viewAgreement = (data) => {
	return {
		type: VIEW_AGREEMENT,
		payload: data
	}
}

export const setRentalTypeId = (typeId) => {
	return {
		type: SET_CONTRACT_TYPE_ID_RENTAL,
		payload: typeId
	}
}