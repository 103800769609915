export const facebookURI = "https://connect.facebook.net/en_US/sdk.js";
export const googleURI = "https://apis.google.com/js/platform.js";
export const isNumberRegex = /\d/;
export const specialCharacterRegex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const loginMobileRegex=/^[1-9][0-9]{9,14}$/;
export const nameRegex = /^[A-Za-z\s]+$/;
export const isMobileRegex = /^[+]\d+$/;
export const isOtpRegex = /\d{6}/;
export const numberOnlyRegex = /^[0-9]+$/;
export const onlyAlphabet = /[a-zA-Z]/;
export const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
export const onKeyPressRegex = /[0-9]/;

export const rentalUploadValidation = {
  maxSize: 5, //MB
  maxCount: 3,
  type: "image",
  format: ["png", "jpg", "jpeg"],
  allowFormatMsg: 'Should alow jpg/png files only',
  maxCountMsg: 'Maximum 3 files to upload',
  maxSizeMsg: 'File size should not exceed 5 MB'
};
export const singUploadValidation = {
  maxSize: 5, //MB
  maxCount: 1,
  type: "image",
  format: ["png", "jpg", "jpeg"],
  allowFormatMsg: 'Should alow jpg/png files only',
  maxCountMsg: 'Maximum 3 files to upload',
  maxSizeMsg: 'File size should not exceed 10 MB'
};
export const realEstateUploadValidation = {
  maxSize: 10, //MB
  type: ["application", "image"],
  format: ["png", "jpg", "jpeg", "pdf"],
  allowFormatMsg: 'Should alow jpg/png/pdf files only',
  maxSizeMsg: 'File size should not exceed 10 MB'
};
export const askExpertUploadValidation = {
  maxSize: 10, //MB
  maxCount: 5,
  type: ["application", "image"],
  format: ["png", "jpg", "jpeg", "pdf"],
  allowFormatMsg: 'Should alow jpg/png/pdf files only',
  maxCountMsg: 'Maximum 5 files to upload',
  maxSizeMsg: 'File size should not exceed 10 MB'
};
export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1000000;
export const HIDE_PAGE = ["/privacy-policy", "/terms-and-conditions"];
export const ONLY_VIEW_USER = ["DOCUMENT_WRITER", "LEGAL_COUNSEL"];
export const ONLY_EDIT_USER = ["VENDOR", "VENDEE"];
export const DOCUMENT_NAME = ["AADHAAR", "PASSPORT"];
export const PAGINATION_PER_PAGE = 5;
export const PAGINATION_PER_PAGE_ASK_EXPERT = 5;
export const ASK_EXPERT_CLOSED_STATUS = "CLOSED";
export const ASK_EXPERT_CREATED_STATUS = "CREATED";
export const ASK_EXPERT_OPENED_STATUS = "OPENED";
export const STAR_RATTING = [5,4,3,2,1];
export const MONTH_NUMBER_FORMAT = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12'
};
export const MONTH_THREE_FORMAT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const countryArray = [
  {
    name: "United States of America",
    al3Code: "USA",
    code: "1",
    value: "United States of America"
  },
  {
    name: "India",
    al3Code: "IND",
    code: "91",
    value: "India"
  },
];

export const modeOfPayment = [
  {
    name: "Cash",
    value: "CASH",
  },
  {
    name: "Cheque",
    value: "CHEQUE",
  },
  {
    name: "DD",
    value: "DD",
  },
  {
    name: "Online",
    value: "ONLINE",
  },
];

export const realEstateType = "real-estate-sale-contract";
export const saleDeedType = "sale-deed";


export const getAskExpertTabAlias =  {
  openTab: "OPENED,CREATED",
  closedTab: "CLOSED"
};
