import { Fragment } from "preact";
import messages from "../messages";
const {
  page: { validationMessage },
} = messages;

const UploadError = ({ fileValidationError, emptyMsg, validInputs }) => {
  const displayErrorMsg = () => {
    const { type, size, count, duplicate, empty } = fileValidationError;
    const { fileDuplicate } = validationMessage;
    const { allowFormatMsg, maxCountMsg, maxSizeMsg } = validInputs;
    return (
      <ul className="text-secondary">
        {empty !== 4 && (
          <FileUploadValidation isValid={empty} text={emptyMsg} />
        )}
        {type !== 4 && (
          <FileUploadValidation isValid={type} text={allowFormatMsg} />
        )}
        {size !== 4 && (
          <FileUploadValidation isValid={size} text={maxSizeMsg} />
        )}
        {count !== 4 && (
          <FileUploadValidation isValid={count} text={maxCountMsg} />
        )}
        {duplicate !== 4 && (
          <FileUploadValidation isValid={duplicate} text={fileDuplicate} />
        )}
      </ul>
    );
  };

  return <Fragment>{displayErrorMsg()}</Fragment>;
};


const FileUploadValidation = ({ isValid, text }) => {
  let highlightClass = "text-secondary";
  switch (isValid) {
    case 1:
      highlightClass = "text-success";
      break;
    case 2:
      highlightClass = "text-danger";
      break;
    default:
      highlightClass = "text-secondary";
      break;
  }
  return <li className={highlightClass}>{text}</li>;
};

export default UploadError;
